import React, { useState } from "react";

import classes from "./FAQSection.module.css";

const accordionItemsData = [
    {
        title: "What is The B.Hive ?",
        text: "The B.Hive is a community hub with a one of a kind vibe, where everyone meets to become the busiest bee they can be(e) !",
    },
    {
        title: "Why Bees and Metaverse?",
        text: `Bees symbolize hard work and productivity. The new world is for those productive persons who will not rest until they reach their goals 
That’s why B.Hive is transcending to the Metaverse while keeping its coffee business, after all what’s better than Coffee to boost your efficiency and to meet other like-minded people.`,
    },
    {
        title: "Who are we?",
        text: `We’re the Worker Bees – a team of experts with diverse backgrounds and experience, leading B.Hive into the metaverse.
In the ‘real’ world, we own multiple coffee shops and hubs that have become home to diverse, vibrant communities.`,
    },

    {
        title: "When is the first launch?",
        text: `We’ll begin with a limited supply presale exclusive to our existing organic B.Hive community. 
We’ll then launch with a second presale that will be available to the wider, international community.`,
    },

    {
        title: "How many Bees are there?",
        text: `There will be a total of 1111 Busy Bee NFTs, released in multiple phases.
Follow our Twitter @thebhivenft or join our Discord to stay up to date on drops. `,
    },
    //   {
    //     title: "How much does it cost to become a Bee?",
    //     text: `Each NFT will be a priced at 0.1 ETH.
    // 2 Mints Per BeeList (Whitelist)
    // Any supply not sold during presales will be Dutch auctioned in a public sale. `,
    //   },
    {
        title: "How do I purchase?",
        text: `To become a Busy Bee, you’ll need a Metamask wallet topped up with enough ETH to cover the NFT price and gas fees.  
We’re offering our community members assistance in our physical B.Hive location. We’ll guide you through the steps, and even send you the ETH or USDT needed to complete the transaction.`,
    },
    {
        title: "What are the royalty fees?",
        text: `Our royalty fees are 7.5% in the secondary market.`,
    },
];

const FAQSection = () => {
    const [openFaqIndex, setOpenFaqIndex] = useState({ index: null, height: null });

    const openAccorion = (index) => {
        const itemText = document.getElementById(`accordion-text${index}`);
        if (index === openFaqIndex.index) {
            setOpenFaqIndex(() => {
                return { index: null, height: null };
            });
        } else {
            setOpenFaqIndex(() => {
                return { index: index, height: itemText.scrollHeight };
            });
        }
    };

    return (
        <div className={`${classes.faqSection} container`}>
            <h2 className={classes.faqSection__title}>FAQ</h2>
            <div className={classes.faqAccordion}>
                {accordionItemsData.map((item, index) => {
                    return (
                        <div
                            key={`${item.title}-${item.index}`}
                            className={`${classes.accordionItem} ${openFaqIndex.index === index ? classes.accordionOpen : undefined}`}
                            onClick={() => {
                                openAccorion(index);
                            }}
                        >
                            <div className={classes.accordionItem__title}>
                                <span>{item.title}</span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.419 17.964">
                                        <path
                                            id="Icon_ionic-ios-arrow-down"
                                            data-name="Icon ionic-ios-arrow-down"
                                            d="M21.9,23.8l11.88-11.889a2.236,2.236,0,0,1,3.171,0,2.264,2.264,0,0,1,0,3.18L23.493,28.557a2.241,2.241,0,0,1-3.1.065L6.842,15.1a2.246,2.246,0,0,1,3.171-3.18Z"
                                            transform="translate(37.606 29.21) rotate(180)"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div
                                id={`accordion-text${index}`}
                                className={classes.accordionItem__text}
                                style={{ height: openFaqIndex.index === index ? `${openFaqIndex.height}px` : "0" }}
                            >
                                {item.text}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FAQSection;
