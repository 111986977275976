import React, { useEffect, useRef } from "react";

import classes from "./Header.module.css";

const Header = () => {
    const headerRef = useRef(null);
    const menuButton = useRef(null);

    useEffect(() => {
        const topNav = document.getElementById("top-nav");
        if (topNav) {
            topNav.querySelectorAll('a[href^="#"]').forEach((anchor) => {
                anchor.addEventListener("click", function (e) {
                    e.preventDefault();

                    let anchorRef = this.getAttribute("href");

                    toggleMenu(true);
                    document.querySelector(`${anchorRef}`).scrollIntoView({
                        behavior: "smooth",
                    });
                });
            });
        }
    }, []);

    const toggleMenu = (removeBodyNocScroll) => {
        menuButton.current.classList.toggle(classes.menuOpened);
        menuButton.current.setAttribute("aria-expanded", menuButton.current.classList.contains(classes.menuOpened));

        headerRef.current.classList.toggle(classes.headerOpen);
        if (removeBodyNocScroll === true) {
            document.body.classList.remove("noscroll");
        } else {
            document.body.classList.toggle("noscroll");
        }
    };

    return (
        <header ref={headerRef} id="top-nav" className={classes.headerContainer}>
            <nav className="container">
                <div className={classes.headerMobileNav}>
                    <div className={` ${classes.headerLogoMobile}`}>
                        <a href="#hero-section">
                            <img src="/assets/images/the-bhive-logo.png" alt="LOGO" />
                        </a>
                    </div>
                    <div className={classes.menuIconWrapper}>
                        <button
                            ref={menuButton}
                            className={classes.menuIcon}
                            onClick={() => {
                                toggleMenu();
                            }}
                            aria-label="Main Menu"
                        >
                            <svg width="60" height="60" viewBox="0 0 100 100">
                                <path
                                    className={`${classes.menuLine} ${classes.menuLine1}`}
                                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                                />
                                <path className={`${classes.menuLine} ${classes.menuLine2}`} d="M 20,50 H 80" />
                                <path
                                    className={`${classes.menuLine} ${classes.menuLine3}`}
                                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <ul className={classes.headerListItems}>
                    <li className={classes.headerItem}>
                        <a href="#about-section">About</a>
                    </li>
                    <li className={classes.headerItem}>
                        <a href="#story-section">Story</a>
                    </li>
                    <li className={classes.headerItem}>
                        <a href="#roadmap-section">Roadmap</a>
                    </li>

                    <li className={` ${classes.headerLogo}`}>
                        <a href="#hero-section">
                            <img src="/assets/images/the-bhive-logo.png" alt="LOGO" />
                        </a>
                    </li>

                    <li className={classes.headerItem}>
                        <a href="#team-section">Team</a>
                    </li>
                    <li className={classes.headerItem}>
                        <a href="#faq-section">FAQ</a>
                    </li>
                    <li className={classes.headerItem}>
                        <a href={"https://mint.thebhive.io"} target="_blank" className={classes.buyNowBtn}>
                            Mint Now
                        </a>
                        {/* <span className={classes.buyNowBtn}>Mint Now</span> */}
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
