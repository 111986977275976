import React, { useEffect } from "react";
import AOS from "aos";

import HomeHeroSection from "../components/Sections/HomeHeroSection/HomeHeroSection";
import AboutSection from "../components/Sections/AboutSection/AboutSection";
import StorySection from "../components/Sections/StorySection/StorySection";
import RoadmapSection from "../components/Sections/RoadmapSection/RoadmapSection";
import TeamSection from "../components/Sections/TeamSection/TeamSection";
import FAQSection from "../components/Sections/FAQSection/FAQSection";

import "../styles/Home.css";

const HomePage = () => {
  useEffect(() => {
    AOS.init({
      disable: "tablet",
      offset: 120,
      delay: 0,
      duration: 750,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <div>
      <section id="hero-section">
        <HomeHeroSection />
      </section>
      <section id="about-section">
        <AboutSection />
      </section>
      <section id="story-section">
        <StorySection />
      </section>
      <section id="roadmap-section">
        <RoadmapSection />
      </section>
      <section id="team-section">
        <TeamSection />
      </section>
      <section id="faq-section">
        <FAQSection />
      </section>
    </div>
  );
};

export default HomePage;
