import React, { useState } from "react";

import TimelineComponent from "../../TimelineComponent/TimelineComponent";

import classes from "./RoadmapSection.module.css";

const season_1_data = [
    {
        title: "Supporting the Community:",
        text: "Are you a freelancer, an artist?\nWe’ll promote your work at B. Hive and on our platforms.\nYou’ll be able to showcase and sell your creations at B. Hive.",
    },
    {
        title: "Ownership",
        text: "Once a holder, you own 0.02% of the 3 sandbox lands for every NFT you hold.\nAny revenue generated from these lands will be distributed accordingly.",
    },
    {
        title: "Discounts",
        text: "Holders benefit from 20% on all B. Hive products and room bookings.",
    },
    {
        title: "Investing in Ourselves:",
        text: "We’ll kick off our activities with seminars that showcase experts from different fields. Learn about NFTs, crypto, Web 3.0 and all the skills needed to keep up with the new virtual era.\nHolders will have access to live and virtual training sessions.",
    },
    {
        title: "Events and Hangouts",
        text: "Holders will have free exclusive access to events and hangouts that will take place frequently.",
    },
    {
        title: "Launching Merchandise & Giveaways:",
        text: "You’ll be able to get your hands on exclusive B.Hive merch, or win one of many Oculus Quest 2’s.",
    },
    {
        title: "Developing your Meta Branches:",
        text: "Busy Bees become shareholders in our Metaverse Hives. There are three lands in The Sandbox already waiting for you! The B.Hive will consist of three smaller Hives that will be open to our worldwide community and will become home to all events.  \n⬡ Hive 1: A space for events, exhibitions, talks and edutainment where you can learn and network.  \n⬡ Hive 2: A space for speed dating, couple and group games, where you can share the fun.  \n⬡ Hive 3: A space that feels like home. A replica of our B.Hive branch, where you can socialize or work in our meeting rooms and offices.",
    },
    {
        title: "Accessing Web 3.0 Platform:",
        text: "You’ll join a wide community of ambitious young professionals, where you can network, share projects, offer services and find the services you’re looking for.",
    },
    // {
    //     title: "Allocating Community Funds:",
    //     text: "20% of our Season 1 proceeds will be allocated to a wallet that will fund our next phase - Season 2.\n This project will be driven by our community of Bees, who, in return, will receive perks such as  \n presale spots and access to Season 2 upgrade potions.",
    // },
    {
        title: "Expanding our Hive:",
        text: "We’ll expand with other Hubs outside Lebanon that will include a coffee shop, where Bees can grab a drink, share a meal, work, and connect, and meeting rooms where exclusive trainings will be offered, free entry level online access and discounts on all advanced courses and all B.Hive products for members.\nWe aim to be a leading Web 3 community.",
    },
];

// const season_2_data = [
//     {
//         title: "Expanding into Dubai:",
//         text: "We’ll expand with a Hub in Dubai that will include:\n⬡ A coffee shop, with meeting rooms, where Bees can grab a drink, share a meal, work, and connect\n⬡ A Web 3.0 Academy, with access to exclusive training services, free entry level online access and discounts on all advanced courses for members\n⬡ Members will have access to career acceleration and job assistance\nWe aim to be the leading community-built Web 3.0 service provider in the MENA region, targeting projects, companies, and government entities. The Hub will generate revenue through all services.\nThe Hub will be 100% owned by our members and all profits will be distributed to the holders accordingly.\n ⬡ A maximum of 5,000 Bees will become shareholders, with the capacity for all Season 1 members to upgrade to Season 2.",
//     },
// ];

const RoadmapSection = () => {
    // const [seasonState, setSeasonState] = useState(1);
    // const changeSeasonState = (newState) => {
    //     try {
    //         const newStateNumber = parseInt(newState);
    //         if (newStateNumber !== seasonState) {
    //             setSeasonState(() => newStateNumber);
    //         }
    //     } catch (e) {
    //         return;
    //     }
    // };

    return (
        <div className={classes.roadmapContainer}>
            {/* // * *********************** */}
            <div className="container">
                <div className={classes.sectionHeadContainer}>
                    <div className={`${classes.headElement} ${classes.active}`}>
                        <span className={classes.headPreTitle}>Roadmap</span>
                        <span className={classes.headTitle}>Season 1</span>
                    </div>
                    {/* <div className={`${classes.headElement} ${seasonState === 2 ? classes.active : ""}`} onClick={() => changeSeasonState(2)}>
                        <span className={classes.headPreTitle}>Roadmap</span>
                        <span className={classes.headTitle}>Season 2</span>
                    </div> */}
                </div>
            </div>
            <hr className={classes.topLine} />
            {/* // * *********************** */}
            <div className="container">
                <div className={classes.timeLineContainer}>
                    <TimelineComponent
                        data={season_1_data}
                        // data={seasonState === 1 ? season_1_data : seasonState === 2 ? season_2_data : []}
                    />
                </div>
            </div>
        </div>
    );
};

export default RoadmapSection;
