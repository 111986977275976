import React from "react";

import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.backgroundImage}>
        <img src="/assets/images/the-bhive-hero-background-img.png" alt="" />
      </div>
      <div className={`container ${classes.footerContent}`}>
        <div className={classes.footerLogo}>
          <img src="/assets/images/the-bhive-logo.png" alt="LOGO" />
        </div>
        <div className={classes.socials}>
          <a href="https://twitter.com/thebhivenft?s=20&t=Vnn7girMnvnGnIrNwm4OzA" target={"_blank"} rel="noreferrer">
            <img src="/assets/images/icons socials/the-bhive-twitter.svg" alt="Twitter Logo" className={classes.icon3d} />
          </a>
          <a href="https://instagram.com/thebhivenft?utm_medium=copy_link" target={"_blank"} rel="noreferrer">
            <img src="/assets/images/icons socials/the-bhive-instagram.svg" alt="Instagram Logo" className={classes.icon3d} />
          </a>
          <a href="http://discord.gg/D4wHHMffdX" style={{ padding: "0 2px" }} target={"_blank"} rel="noreferrer">
            <img src="/assets/images/icons socials/the-bhive-discord.svg" alt="Discord Logo" className={classes.icon3d} />
          </a>
          {/* <a href="#linkedin">
            <img src="/assets/images/icons socials/the-bhive-linkedin-in.svg" alt="LinkedIn Logo" className={classes.icon3d} />
          </a> */}
        </div>
        {/* <div className={classes.buyLink}>
          <a href="#buy">Click here to buy</a>
        </div> */}
        <div className={classes.copyrightText}>© 2022 The b.hive. All rights reserved</div>
      </div>
    </footer>
  );
};

export default Footer;
