import React, { useRef } from "react";
import Slider from "react-slick";

import classes from "./TeamSection.module.css";

const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: true,
};

const sliderData = [
    {
        title: "Mazen Jradi",
        subtitle: "CEO",
        url: "https://www.linkedin.com/in/mazen-jradi-mba-9a9ba923",
        image: "/assets/images/slider images/the-bhive-slide-1.jpeg",
    },
    { title: "Dania Jradi", subtitle: "CCO", url: "http://linkedin.com/in/dania-jradi", image: "/assets/images/slider images/the-bhive-slide-5.png" },
    {
        title: "Rebecca Haddad",
        subtitle: "Design Team",
        url: "https://www.linkedin.com/in/rebecca-haddad-394562a5",
        image: "/assets/images/slider images/the-bhive-slide-3.jpeg",
    },
    {
        title: "Jad Fenergi",
        subtitle: "Artist",
        url: "https://lb.linkedin.com/in/jadfenergi",
        image: "/assets/images/slider images/the-bhive-slide-9.jpeg",
    },
    {
        title: "Karim Saadeh",
        subtitle: "Community Manager",
        url: "https://instagram.com/karim_saadeh?utm_medium=copy_link",
        image: "/assets/images/slider images/the-bhive-slide-2.png",
        iconType: "insta",
    },
    {
        title: "Ahmad Abiad",
        subtitle: "Marketing Consultant",
        url: "https://www.linkedin.com/in/ahmad-el-abiad-0919b014",
        image: "/assets/images/slider images/the-bhive-slide-6.png",
    },
    {
        title: "Michelle Boulos",
        subtitle: "Brand Consultant ",
        url: "https://www.linkedin.com/in/michelle-boulos/",
        image: "/assets/images/slider images/the-bhive-slide-8.png",
    },
    {
        title: "Moe",
        subtitle: "Discord Expert",
        url: "https://www.instagram.com/_mnassab/",
        image: "/assets/images/slider images/the-bhive-slide-7.jpeg",
        iconType: "insta",
    },
];

const TeamSection = () => {
    const sliderRef = useRef("");

    return (
        <div className={classes.sectionContainer}>
            <div className="container" data-aos="fade-up">
                <h2 className={classes.sectionTitle}>Team</h2>
                <p className={classes.sectionSubtitle}>The tremendous amount of work from our incredible Team, allowed The B.Hive to be born</p>
            </div>
            <div className={classes.sliderContainer}>
                <Slider {...sliderSettings} ref={sliderRef}>
                    {sliderData.map((item, index) => (
                        <div className={classes.slideWrapper} key={index}>
                            <div className={classes.slideContentContainer}>
                                <div className={classes.slideContentWrapper}>
                                    <p className={classes.slideTitle}>{item.title}</p>
                                    <p className={classes.slideSubtitle}>{item.subtitle}</p>
                                </div>
                            </div>
                            <div className={classes.slideBackgroundContainer}>
                                <div className={classes.slideBackgroundWrapper}>
                                    <div className={classes.c_glitch} style={{ backgroundImage: `url('${item.image}')` }}>
                                        <div className={classes.c_glitch__img} style={{ backgroundImage: `url('${item.image}')` }}></div>
                                        <div className={classes.c_glitch__img} style={{ backgroundImage: `url('${item.image}')` }}></div>
                                        <div className={classes.c_glitch__img} style={{ backgroundImage: `url('${item.image}')` }}></div>
                                        <div className={classes.c_glitch__img} style={{ backgroundImage: `url('${item.image}')` }}></div>
                                        <div className={classes.c_glitch__img} style={{ backgroundImage: item.image }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.slideSocialContainer}>
                                <a href={item.url} target="_blank" rel="noreferrer">
                                    <div className={classes.slideSocialWrapper}>
                                        {item.iconType === "insta" ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="54.084" height="54.072" viewBox="0 0 54.084 54.072">
                                                <path
                                                    id="Icon_awesome-instagram"
                                                    data-name="Icon awesome-instagram"
                                                    d="M27.043,15.41A13.863,13.863,0,1,0,40.906,29.274,13.841,13.841,0,0,0,27.043,15.41Zm0,22.876a9.013,9.013,0,1,1,9.013-9.013A9.029,9.029,0,0,1,27.043,38.287ZM44.707,14.843a3.234,3.234,0,1,1-3.234-3.234A3.226,3.226,0,0,1,44.707,14.843Zm9.182,3.282c-.205-4.332-1.194-8.168-4.368-11.33s-7-4.151-11.33-4.368c-4.464-.253-17.845-.253-22.309,0-4.319.205-8.156,1.194-11.33,4.356S.4,13.781.185,18.113c-.253,4.464-.253,17.845,0,22.309.205,4.332,1.194,8.168,4.368,11.33s7,4.151,11.33,4.368c4.464.253,17.845.253,22.309,0,4.332-.205,8.168-1.194,11.33-4.368s4.151-7,4.368-11.33c.253-4.464.253-17.833,0-22.3ZM48.121,45.212a9.125,9.125,0,0,1-5.14,5.14c-3.559,1.412-12.005,1.086-15.939,1.086s-12.391.314-15.939-1.086a9.125,9.125,0,0,1-5.14-5.14C4.553,41.653,4.878,33.207,4.878,29.274S4.565,16.882,5.964,13.335A9.125,9.125,0,0,1,11.1,8.2c3.559-1.412,12.005-1.086,15.939-1.086S39.434,6.8,42.981,8.2a9.125,9.125,0,0,1,5.14,5.14c1.412,3.559,1.086,12.005,1.086,15.939S49.533,41.665,48.121,45.212Z"
                                                    transform="translate(0.005 -2.238)"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                                                <path
                                                    id="Icon_awesome-linkedin-in"
                                                    data-name="Icon awesome-linkedin-in"
                                                    d="M7.051,31.5H.52V10.47H7.051ZM3.782,7.6A3.8,3.8,0,1,1,7.564,3.783,3.814,3.814,0,0,1,3.782,7.6ZM31.493,31.5H24.976V21.263c0-2.44-.049-5.569-3.4-5.569-3.4,0-3.916,2.651-3.916,5.393V31.5H11.142V10.47h6.263v2.869H17.5a6.862,6.862,0,0,1,6.179-3.4c6.609,0,7.824,4.352,7.824,10.005V31.5Z"
                                                    transform="translate(0 0)"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="container">
                <div className={classes.arrowsContainer}>
                    <button
                        className={`${classes.arrow} ${classes.next}`}
                        onClick={() => {
                            sliderRef.current.slickPrev();
                        }}
                    ></button>
                    <button
                        className={`${classes.arrow} ${classes.prev}`}
                        onClick={() => {
                            sliderRef.current.slickNext();
                        }}
                    ></button>
                </div>
            </div>
        </div>
    );
};

export default TeamSection;
