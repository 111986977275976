import React from "react";

import classes from "./AboutSection.module.css";

const AboutSection = () => {
  return (
    <div className="container">
      <div className={classes.aboutContainer}>
        <p className={classes.aboutText} data-aos="fade-up">
          <strong>Welcome to The B.Hive,</strong> a collection of 1111 unique digital collectibles, stored as ERC-721 tokens on the Ethereum
          blockchain and hosted on IPFS.
          <br />
          With more than 150 hand drawn traits, each NFT is unique and comes with membership to our exclusive community.
        </p>
      </div>
    </div>
  );
};

export default AboutSection;
