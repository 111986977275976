import React from "react";

import classes from "./HomeHeroSection.module.css";

const HomeHeroSection = () => {
  return (
    <div className={classes.homeHeroSection}>
      <div className={`${classes.heroContentWrapper} container`}>
        <div className={classes.heroTextWrapper}>
          <div className={classes["heroText__intro"]} data-aos="fade-up" data-aos-delay="1200">
            The first coffee shop
          </div>
          <div className={classes["heroText__title"]} data-aos="fade-up" data-aos-delay="1200">
            to introduce NFT
          </div>
          <div
            className={classes["heroText__arrow"]}
            onClick={() => {
              document.querySelector(`#about-section`).scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            <img src="/assets/images/the-bhive-arrow-down.png" alt="arrow down" />
          </div>
        </div>
      </div>
      <div className={`${classes["heroIntro__characters"]}`}>
        <div className={classes.character__img} data-aos="fade-right" data-aos-delay="900">
          <img src="/assets/images/Hero characters images/the-bhive-final copy.png" alt="" />
        </div>
        <div className={classes.character__img} data-aos="fade-up" data-aos-delay="300">
          <img src="/assets/images/Hero characters images/the-bhive-final-bee-base copy.png" alt="" />
        </div>
        <div className={classes.character__img} data-aos="fade-up" data-aos-delay="300">
          <img src="/assets/images/Hero characters images/the-bhive-final -bee-business man copy.png" alt="" />
        </div>
        <div className={classes.character__img} data-aos="fade-left" data-aos-delay="600">
          <img src="/assets/images/Hero characters images/the-bhive-final-bee-lab copy.png" alt="" />
        </div>
      </div>
      <div className={classes.heroBottomGradient}>{/* <img src="/assets/images/hero-linear-fade.svg" alt="linear fade" /> */}</div>
    </div>
  );
};

export default HomeHeroSection;
