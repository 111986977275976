import React from "react";

import classes from "./GoToTop.module.css";

const GoToTop = () => {
  return (
    <div
      id="goToTop"
      className={classes.goToTop}
      onClick={() => {
        document.querySelector(`#hero-section`).scrollIntoView({
          behavior: "smooth",
        });
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="31.419" height="17.964" viewBox="0 0 31.419 17.964">
        <path
          id="Icon_ionic-ios-arrow-down"
          data-name="Icon ionic-ios-arrow-down"
          d="M21.9,23.8l11.88-11.889a2.236,2.236,0,0,1,3.171,0,2.264,2.264,0,0,1,0,3.18L23.493,28.557a2.241,2.241,0,0,1-3.1.065L6.842,15.1a2.246,2.246,0,0,1,3.171-3.18Z"
          transform="translate(37.606 29.21) rotate(180)"
        />
      </svg>
    </div>
  );
};

export default GoToTop;
