import React, { useEffect } from "react";

import classes from "./TimelineComponent.module.css";

function observerCallback(entries, observer) {
  const { isIntersecting, target, boundingClientRect } = entries[0];
  const timelineItems = document.querySelectorAll(`.${classes.timelineItem}`);
  let targetIndex = [].indexOf.call(timelineItems, target);

  if (isIntersecting) {
    !target.classList.contains(classes.active) && target.classList.add(classes.active);
    if (targetIndex > 0) {
      timelineItems[targetIndex - 1].classList.add(classes.activeLine);
    }
  } else {
    if (boundingClientRect.top > 50) {
      if (target.classList.contains(classes.active)) {
        target.classList.remove(classes.active);
        if (targetIndex > 0) {
          timelineItems[targetIndex - 1].classList.remove(classes.activeLine);
        }
      }
    }
  }
}
// * *****************
const TimelineComponent = ({ data }) => {
  useEffect(() => {
    const observerConfig = {
      root: null,
      rootMargin: window.innerWidth > 992 ? "-40px" : "-10px",
      threshold: 1.0,
    };

    const timelineItems = document.querySelectorAll(`.${classes.timelineItem}`);
    timelineItems.forEach((timeline) => {
      const observer = new IntersectionObserver(observerCallback, observerConfig);
      observer.observe(timeline);
    });

    return () => {
      timelineItems.forEach((timeline) => {
        const observer = new IntersectionObserver(observerCallback, observerConfig);
        observer.unobserve(timeline);
        observer.disconnect();
      });
    };
  }, [data]);

  return (
    <div className={classes.timelineContainer}>
      <div className={classes.timelineWrapper}>
        {data &&
          data.map((item, index) => (
            <div className={classes.timelineItem} key={index}>
              <span className={classes.timelineTitle} data-aos="fade-up">
                {item.title || ""}
              </span>
              <span className={classes.timelineText} data-aos="fade-up">
                {item.text || ""}
              </span>
            </div>
          ))}
      </div>
      <div className={classes.timelineLine}></div>
    </div>
  );
};

export default TimelineComponent;
