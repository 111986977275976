import React, { useEffect } from "react";

import Header from "./components/Layout/Header/Header";
import Footer from "./components/Layout/Footer/Footer";
import GoToTop from "./components/GoToTop/GoToTop";

import HomePage from "./pages/HomePage";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// * *****************************
// * *****************************
const App = () => {
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      const aboutSection = document.getElementById("about-section");
      if (aboutSection.getBoundingClientRect().top <= 0) {
        document.getElementById("goToTop").style.opacity = 1;
      } else {
        document.getElementById("goToTop").style.opacity = 0;
      }
    });
  }, []);

  return (
    <>
      <Header />
      <main>
        <HomePage />
      </main>
      <Footer />
      <GoToTop />
    </>
  );
};

export default App;
