import React from "react";

import classes from "./StorySection.module.css";

const StorySection = () => {
  return (
    <div className="container">
      <div className={classes.storyContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.contentImageContainer}>
            <div className={classes.contentImageWrapper} data-aos="fade" data-aos-delay="600">
              <img src="/assets/images/the-bhive-story-bee.png" alt="bee" />
            </div>
          </div>
          <div className={classes.contentTextContainer}>
            <h2 className={classes.contentTitle} data-aos="fade-right">
              Story
            </h2>
            <div data-aos="fade-right" data-aos-delay="300">
              <p className={classes.contentTextBold}>
                In 2018, we launched B.Hive - a coffee shop-hub hybrid in the heart of Beirut. In just a few years, we’ve developed into so much more.
                We’ve become a community.
              </p>
              <p className={classes.contentText}>
                When we began, we wanted to give our Bees a space that inspires. A place they could call home. And they did.
                <br />
                B.Hive evolved because of the incredible people that chose to become a part of our colony. Their positivity, spirit, and ambition
                attracted more like-minded Bees and turned our concept into a true Hive.
                <br />
                Our journey into the metaverse is inspired by our beloved Bees. As many of them leave the Hive and settle into new places across the
                world, we want to preserve the bond that turned us into family and invite the world to join.
              </p>
            </div>
          </div>
        </div>
        <div className={classes.logoContainer}>
          <div className={classes.logoWrapper}>
            <img src="/assets/images/the-bhive-story-logo.png" alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorySection;
